@font-face {
  font-family: 'BM-Biotif';
  src: url('./fonts/bm-biotif/BM-Biotif-Bold.eot'), url('./fonts/bm-biotif/BM-Biotif-Bold.woff2') format('woff2'),
    url('./fonts/bm-biotif/BM-Biotif-Bold.woff') format('woff'),
    url('./fonts/bm-biotif/BM-Biotif-Bold.ttf') format('truetype'),
    url('./fonts/bm-biotif/BM-Biotif-Bold.svg#BM-Biotif-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'uknumberplate';
  src: url('./fonts/number-plate/uknumberplate-webfont.eot'),
    url('./fonts/number-plate/uknumberplate-webfont.woff2') format('woff2'),
    url('./fonts/number-plate/uknumberplate-webfont.woff') format('woff'),
    url('./fonts/number-plate/uknumberplate-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  color: #515151;
  font-family: 'Roboto', sans-serif;
  background: #f4f1f4;
  &.white-background {
    background: #fff;
  }

  &.retrieval-body {
    overflow-x: hidden;
  }
}
html {
  &.retrieval-body {
    overflow-x: hidden;
  }
}

body.noScroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
a strong {
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}

.error-wrapper {
  .error {
    font-size: 14px;
    font-weight: bold;
    margin-top: -36px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    background-color: floralwhite;
    color: #fcb608;
    border-top: 1px solid #fcb608;
    border-bottom: 1px solid #fcb608;
    padding: 10px 0 10px;
    .date {
      margin-top: 20px;
      margin-bottom: -25px;
      top: -55px;
    }
    &::before,
    &::after {
      border: solid transparent;
      bottom: 100%;
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: 20px;
    }
    &::before {
      border-width: 11px;
      border-bottom-color: #fcb608;
      margin-left: -11px;
    }
  }
}

// legacy rules for legacy components:
.legacy-wrapper {
  width: 100%;
  height: 60px;
  background: #fff;
  position: relative;
  z-index: 5;
  @media (min-width: 768px) {
    height: 75px;
  }
  @media (min-width: 1170px) {
    height: 150px;
  }
  .header-main {
    @media (min-width: 768px) {
      top: -11px;
    }
    @media (min-width: 1170px) {
      top: 20px;
    }
  }
  .container {
    .row {
      &.mid-margin {
        margin-top: 0 !important;
        padding-top: 20px;
      }
    }
  }
  .col-xs-7 {
    width: 49.33333% !important;
  }

  @media (min-width: 768px) AND (max-width: 1170px) {
    height: 74px;
  }
  #desktop-header {
    @media (min-width: 768px) AND (max-width: 1170px) {
      height: 74px;
    }
  }
}

#desktop-header {
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .flexMe {
    position: relative;
    top: 10px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: #320b42;
}
